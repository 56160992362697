<template>
  <div class="animated fadeIn">
    <b-form
      novalidate
      @submit.prevent="onSubmit"
    >
      <div class="main-info">
        <div class="main-info__body">
          <h1 class="main-info__body-title">
            Создание габарита
          </h1>
          <b-row>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Тоннаж<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                >
                  <b-form-input
                    id="weight"
                    v-model="form.weight"
                    type="number"
                    name="weight"
                    min="0"
                    placeholder="Введите тоннаж"
                    autocomplete="weight"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Объем от<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                  description="Значение указывается в метрах кубических"
                >
                  <b-form-input
                    id="route-price"
                    v-model="form.volumeFrom"
                    type="number"
                    name="route-price"
                    min="0"
                    placeholder="Введите объем от"
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="4"
            >
              <div class="main-info__item">
                <p class="main-info__item-title">
                  Объем до<span class="text-danger">*</span>
                </p>
                <b-form-group
                  id="usernameInputGroup1"
                  class="form-group-el-select"
                  description="Значение указывается в метрах кубических"
                >
                  <b-form-input
                    id="route-price"
                    v-model="form.volumeUpTo"
                    type="number"
                    name="route-price"
                    min="0"
                    placeholder="Введите объем до"
                    autocomplete="route-price"
                    @wheel="$event.target.blur()"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div class="fixed-buttons">
        <b-button
          type="button"
          variant="secondary"
          class="mr-2"
          :class="{'loading' : loading}"
          @click="$router.go(-1)"
        >
          Назад
        </b-button>
        <b-button
          type="submit"
          variant="primary"
          class="mr-2"
          :class="{'loading' : loading}"
          :disabled="loading || !form.weight || !form.volumeFrom || !form.volumeUpTo"
        >
          Создать габарит
        </b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import ElSelectClearable from '../../components/ElSelectClearable.vue';
import {transportVehiclesDimensionTypeCreate} from '../../services/api';
import {validationMixin} from 'vuelidate';
import notifications from '../../components/mixins/notifications';

export default {
  name: 'CustomerDimensionsView',
  components: {
    ElSelectClearable,
  },
  mixins: [validationMixin, notifications],
  data() {
    return {
      form: {
        weight: null,
        volumeFrom: null,
        volumeUpTo: null,
      },
      loading: false,
    };
  },
  computed: {
    formStr() {
      const calculationFormData = {};
      if (this.form.weight) {
        calculationFormData.weight = this.form.weight;
      }
      if (this.form.volumeFrom) {
        calculationFormData.volumeFrom = this.form.volumeFrom;
      }
      if (this.form.volumeUpTo) {
        calculationFormData.volumeUpTo = this.form.volumeUpTo;
      }
      return calculationFormData;
    },
  },
  mounted() {
  },
  methods: {
    async onSubmit() {
      this.loading = true;
      const response = await transportVehiclesDimensionTypeCreate(this.$store.state.user.ownerId, this.formStr);
      if (response && response.status === 201) {
        this.showSuccess('Габарит создан');
        this.$router.push({path: `/dimensions/customer/view/id${response.data.id}`});
      }
      this.loading = false;
    },
  },
};
</script>
